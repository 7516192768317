<script setup>
const uniqueId = `mail_grad${Math.random().toString(36).substring(2, 7)}`;
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="`url(#${uniqueId})`" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.0625 3.125C3.31658 3.125 2.60121 3.42132 2.07376 3.94876C1.54632 4.47621 1.25 5.19158 1.25 5.9375V14.0625C1.25 14.8084 1.54632 15.5238 2.07376 16.0512C2.60121 16.5787 3.31658 16.875 4.0625 16.875H15.9375C16.6834 16.875 17.3988 16.5787 17.9262 16.0512C18.4537 15.5238 18.75 14.8084 18.75 14.0625V5.9375C18.75 5.19158 18.4537 4.47621 17.9262 3.94876C17.3988 3.42132 16.6834 3.125 15.9375 3.125H4.0625ZM17.5 6.50187L10 10.54L2.5 6.50187V5.9375C2.5 5.5231 2.66462 5.12567 2.95765 4.83265C3.25067 4.53962 3.6481 4.375 4.0625 4.375H15.9375C16.3519 4.375 16.7493 4.53962 17.0424 4.83265C17.3354 5.12567 17.5 5.5231 17.5 5.9375V6.50187ZM2.5 7.92125L9.70375 11.8006C9.7948 11.8496 9.89659 11.8753 10 11.8753C10.1034 11.8753 10.2052 11.8496 10.2963 11.8006L17.5 7.92125V14.0625C17.5 14.4769 17.3354 14.8743 17.0424 15.1674C16.7493 15.4604 16.3519 15.625 15.9375 15.625H4.0625C3.6481 15.625 3.25067 15.4604 2.95765 15.1674C2.66462 14.8743 2.5 14.4769 2.5 14.0625V7.92125Z"
    />
    <defs>
      <linearGradient :id="uniqueId" x1="23.25" y1="12.1519" x2="0.75" y2="12.1519" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
