<script setup>
const uniqueId = `facebook_grad${Math.random().toString(36).substring(2, 7)}`;
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="`url(#${uniqueId})`" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.85 4.14578C14.6925 2.98877 13.2178 2.20099 11.6126 1.88205C10.0074 1.5631 8.34361 1.72732 6.8317 2.35394C5.31979 2.98056 4.02765 4.04143 3.11866 5.4024C2.20967 6.76337 1.72467 8.36333 1.72498 9.99994C1.72498 12.195 2.59667 14.3002 4.14841 15.8527C5.70016 17.4052 7.80493 18.278 9.99998 18.2791C11.0862 18.2796 12.162 18.0672 13.1666 17.6541C14.1681 17.2314 15.079 16.6203 15.85 15.8541C16.6188 15.0854 17.2287 14.1727 17.6449 13.1683C18.061 12.1638 18.2751 11.0872 18.2751 9.99994C18.2751 8.9127 18.061 7.8361 17.6449 6.83163C17.2287 5.82717 16.6188 4.91451 15.85 4.14578ZM15.2666 15.2624C14.075 16.4592 12.5114 17.2145 10.8333 17.4041V11.9374H12.4C12.621 11.9374 12.833 11.8496 12.9892 11.6934C13.1455 11.5371 13.2333 11.3251 13.2333 11.1041C13.2333 10.8831 13.1455 10.6711 12.9892 10.5149C12.833 10.3586 12.621 10.2708 12.4 10.2708H10.8333V7.98744C10.8333 7.76643 10.9211 7.55447 11.0774 7.39819C11.2337 7.24191 11.4456 7.15411 11.6666 7.15411H12.6666C12.8877 7.15411 13.0996 7.06631 13.2559 6.91003C13.4122 6.75375 13.5 6.54179 13.5 6.32078C13.5 6.09976 13.4122 5.8878 13.2559 5.73152C13.0996 5.57524 12.8877 5.48744 12.6666 5.48744H11.25C10.6974 5.48744 10.1675 5.70694 9.77684 6.09764C9.38614 6.48834 9.16664 7.01824 9.16664 7.57078V10.2708H7.60831C7.3873 10.2708 7.17533 10.3586 7.01905 10.5149C6.86277 10.6711 6.77498 10.8831 6.77498 11.1041C6.77498 11.3251 6.86277 11.5371 7.01905 11.6934C7.17533 11.8496 7.3873 11.9374 7.60831 11.9374H9.16664V17.4041C7.61905 17.2275 6.16604 16.5692 5.01285 15.5222C3.85966 14.4751 3.06457 13.0922 2.73989 11.5688C2.41521 10.0454 2.57736 8.45843 3.20342 7.03216C3.82948 5.60588 4.88781 4.41234 6.22893 3.62013C7.57005 2.82791 9.12617 2.47706 10.6775 2.61712C12.2288 2.75719 13.6969 3.3811 14.8745 4.40073C16.052 5.42035 16.8794 6.78416 17.2399 8.29951C17.6003 9.81485 17.4756 11.4052 16.8833 12.8458C16.5061 13.7485 15.957 14.5692 15.2666 15.2624Z"
    />
    <defs>
      <linearGradient :id="uniqueId" x1="23.25" y1="12.1519" x2="0.75" y2="12.1519" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
