<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="url(#paint0_linear_14935_64182)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0312 15.5625C17.6421 15.5625 16.4077 16.2376 15.6385 17.2768L9.0165 13.8926C9.12759 13.5152 9.1875 13.116 9.1875 12.7031C9.1875 12.1439 9.07786 11.6098 8.87934 11.121L15.8197 6.95105C16.594 7.85995 17.7464 8.4375 19.0312 8.4375C21.3575 8.4375 23.25 6.54497 23.25 4.21875C23.25 1.89253 21.3575 0 19.0312 0C16.705 0 14.8125 1.89253 14.8125 4.21875C14.8125 4.75641 14.9138 5.27077 15.098 5.74406L8.14031 9.92438C7.3665 9.04228 6.23161 8.48438 4.96875 8.48438C2.64253 8.48438 0.75 10.3769 0.75 12.7031C0.75 15.0293 2.64253 16.9219 4.96875 16.9219C6.38072 16.9219 7.63275 16.2246 8.39892 15.1563L15.0017 18.5306C14.8788 18.9259 14.8125 19.346 14.8125 19.7812C14.8125 22.1075 16.705 24 19.0312 24C21.3575 24 23.25 22.1075 23.25 19.7812C23.25 17.455 21.3575 15.5625 19.0312 15.5625ZM19.0312 1.40625C20.5821 1.40625 21.8438 2.66794 21.8438 4.21875C21.8438 5.76956 20.5821 7.03125 19.0312 7.03125C17.4804 7.03125 16.2188 5.76956 16.2188 4.21875C16.2188 2.66794 17.4804 1.40625 19.0312 1.40625ZM4.96875 15.5156C3.41794 15.5156 2.15625 14.2539 2.15625 12.7031C2.15625 11.1523 3.41794 9.89062 4.96875 9.89062C6.51956 9.89062 7.78125 11.1523 7.78125 12.7031C7.78125 14.2539 6.51956 15.5156 4.96875 15.5156ZM19.0312 22.5938C17.4804 22.5938 16.2188 21.3321 16.2188 19.7812C16.2188 18.2304 17.4804 16.9688 19.0312 16.9688C20.5821 16.9688 21.8438 18.2304 21.8438 19.7812C21.8438 21.3321 20.5821 22.5938 19.0312 22.5938Z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14935_64182"
        x1="23.25"
        y1="12.1519"
        x2="0.75"
        y2="12.1519"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <clipPath id="clip0_14935_64182">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
