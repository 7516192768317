<script setup>
const uniqueId = `pingrad${Math.random().toString(36).substring(2, 7)}`;
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="`url(#${uniqueId})`" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.875 8.7499C16.875 10.5132 16.2578 12.1249 15.1383 13.2897C14.093 14.3749 12.6758 14.9999 11.25 14.9999C9.85313 14.9999 8.92031 14.5421 8.32578 14.0624L7.48359 17.6429C7.45136 17.7801 7.3737 17.9023 7.26322 17.9898C7.15274 18.0773 7.01593 18.1249 6.875 18.1249C6.82662 18.1248 6.7784 18.1193 6.73125 18.1085C6.65104 18.0901 6.57525 18.056 6.50824 18.0082C6.44122 17.9605 6.38431 17.8999 6.34075 17.8301C6.2972 17.7603 6.26787 17.6825 6.25444 17.6013C6.24101 17.5201 6.24375 17.4371 6.2625 17.3569L8.7625 6.73193C8.80042 6.57052 8.9009 6.43079 9.04185 6.34346C9.18279 6.25614 9.35265 6.22839 9.51406 6.26631C9.67547 6.30422 9.81521 6.40471 9.90253 6.54565C9.98985 6.6866 10.0176 6.85646 9.97969 7.01787L8.65938 12.6272C8.90625 12.9686 9.63281 13.7499 11.25 13.7499C13.4008 13.7499 15.625 11.8796 15.625 8.7499C15.6247 7.88729 15.4259 7.03632 15.0442 6.26278C14.6625 5.48924 14.1079 4.81386 13.4235 4.28886C12.739 3.76386 11.943 3.40331 11.097 3.23509C10.2509 3.06687 9.37753 3.09548 8.54431 3.31871C7.71109 3.54195 6.94037 3.95382 6.29175 4.52249C5.64313 5.09116 5.13399 5.80138 4.8037 6.59825C4.4734 7.39511 4.3308 8.25727 4.38692 9.11805C4.44304 9.97883 4.69638 10.8152 5.12734 11.5624C5.20508 11.7056 5.22385 11.8734 5.17965 12.0302C5.13545 12.187 5.03177 12.3204 4.89071 12.4019C4.74964 12.4833 4.58233 12.5065 4.42442 12.4665C4.26651 12.4264 4.13048 12.3263 4.04531 12.1874C3.51831 11.2741 3.20843 10.2519 3.13964 9.1997C3.07086 8.14753 3.24502 7.09363 3.64864 6.11952C4.05227 5.14541 4.67454 4.27719 5.46734 3.58202C6.26014 2.88685 7.20222 2.38334 8.22072 2.11045C9.23921 1.83757 10.3068 1.8026 11.341 2.00827C12.3752 2.21394 13.3482 2.65473 14.1848 3.29655C15.0213 3.93836 15.6991 4.764 16.1656 5.70962C16.6321 6.65523 16.8748 7.69548 16.875 8.7499Z"
    />
    <defs>
      <linearGradient :id="uniqueId" x1="23.25" y1="12.1519" x2="0.75" y2="12.1519" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
