<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    width="24"
    height="24"
  >
    <path
      style="fill: #e6f3ff"
      d="M24 4.957v14.087c0 1.299 -1.049 2.348 -2.348 2.348H2.348C1.049 21.391 0 20.343 0 19.043V4.957c0 -0.042 0 -0.083 0.005 -0.125c0.063 -1.2 1.017 -2.155 2.217 -2.217c0.042 -0.005 0.083 -0.005 0.125 -0.005h19.304c0.042 0 0.089 0 0.13 0.005c1.195 0.063 2.15 1.017 2.212 2.212C24 4.868 24 4.915 24 4.957z"
    />
    <path
      style="fill: #cfdbe6"
      d="M21.783 2.614c-0.042 -0.005 -0.089 -0.005 -0.13 -0.005H12v18.783h9.652c1.299 0 2.348 -1.049 2.348 -2.348V4.957c0 -0.042 0 -0.089 -0.005 -0.13C23.932 3.631 22.977 2.677 21.783 2.614z"
    />
    <path
      style="fill: #ff4b26"
      d="M23.995 4.826c-0.063 -1.195 -1.017 -2.15 -2.212 -2.212C17.948 6.449 15.795 8.602 12 12.397C10.561 10.956 3.638 4.029 2.223 2.614c-1.2 0.063 -2.155 1.017 -2.217 2.217C0 4.873 0 4.915 0 4.957v14.087c0 1.299 1.049 2.348 2.348 2.348h0.783V7.957l7.763 7.758c0.303 0.308 0.704 0.459 1.106 0.459c0.402 0 0.803 -0.151 1.106 -0.459l7.763 -7.763v13.44h0.783c1.299 0 2.348 -1.049 2.348 -2.348V4.957C24 4.915 24 4.868 23.995 4.826z"
    />
    <path
      style="fill: #d93f21"
      d="M13.106 15.715l7.763 -7.763v13.44h0.783c1.299 0 2.348 -1.049 2.348 -2.348V4.957c0 -0.042 0 -0.089 -0.005 -0.13c-0.063 -1.195 -1.017 -2.15 -2.212 -2.212C17.948 6.449 15.795 8.602 12 12.397v3.777C12.402 16.174 12.803 16.023 13.106 15.715z"
    />
  </svg>
</template>
