<script setup>
const uniqueId = `whatsapp_grad${Math.random().toString(36).substring(2, 7)}`;
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="`url(#${uniqueId})`" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.40502 5.97511C7.55752 5.97928 7.72669 5.98761 7.88752 6.34428C7.99419 6.58178 8.17336 7.01928 8.32002 7.37595C8.43419 7.65345 8.52752 7.88178 8.55086 7.92845C8.60419 8.03511 8.63752 8.15761 8.56752 8.30178L8.54419 8.35011C8.49642 8.46208 8.43178 8.56606 8.35252 8.65845C8.31224 8.7052 8.27252 8.75242 8.23336 8.80011C8.16252 8.88678 8.09169 8.97178 8.03169 9.03178C7.92419 9.13845 7.81336 9.25345 7.93669 9.46678C8.06086 9.68011 8.49336 10.3818 9.13252 10.9476C9.60035 11.3688 10.1367 11.707 10.7184 11.9476C10.7767 11.9726 10.8242 11.9934 10.86 12.0109C11.0742 12.1176 11.2017 12.1009 11.325 11.9576C11.4492 11.8134 11.8609 11.3334 12.0059 11.1201C12.1459 10.9068 12.2892 10.9401 12.4875 11.0134C12.6859 11.0876 13.7409 11.6051 13.955 11.7109L14.0742 11.7693C14.2234 11.8401 14.3242 11.8893 14.3675 11.9609C14.4209 12.0518 14.4209 12.4776 14.2442 12.9793C14.0625 13.4793 13.1884 13.9593 12.7925 13.9959L12.68 14.0093C12.3167 14.0526 11.8567 14.1093 10.2167 13.4634C8.19502 12.6684 6.86086 10.6968 6.59169 10.2976C6.57732 10.2763 6.5626 10.2551 6.54752 10.2343L6.54252 10.2276C6.42002 10.0634 5.66919 9.05928 5.66919 8.02261C5.66919 7.03095 6.15836 6.51428 6.38086 6.27928L6.42002 6.23761C6.49204 6.15626 6.58004 6.0906 6.67853 6.04472C6.77702 5.99884 6.8839 5.97373 6.99252 5.97095C7.13669 5.97095 7.28169 5.97095 7.40502 5.97511Z"
      fill="url(#whatsapp_grad)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.82003 17.7757C1.80432 17.8322 1.8038 17.8918 1.81851 17.9486C1.83322 18.0054 1.86264 18.0572 1.90381 18.099C1.94497 18.1408 1.99642 18.1709 2.05297 18.1864C2.10951 18.2019 2.16915 18.2023 2.22586 18.1874L6.06503 17.184C7.2831 17.8438 8.64642 18.1895 10.0317 18.1899H10.035C14.6067 18.1899 18.3334 14.484 18.3334 9.92988C18.3363 8.84365 18.1231 7.7677 17.7062 6.76466C17.2893 5.76162 16.677 4.85154 15.905 4.08738C15.1345 3.31678 14.2192 2.70617 13.2118 2.29071C12.2044 1.87524 11.1247 1.66312 10.035 1.66654C5.46336 1.66654 1.73669 5.37154 1.73669 9.92571C1.73669 11.3749 2.11836 12.7982 2.84503 14.054L1.82003 17.7757ZM4.05086 14.3857C4.09492 14.2258 4.10671 14.0587 4.08554 13.8941C4.06436 13.7296 4.01065 13.5709 3.92753 13.4274C3.31097 12.3634 2.98641 11.1554 2.98669 9.92571C2.98669 6.06738 6.14753 2.91654 10.035 2.91654C11.9267 2.91654 13.6917 3.64738 15.0234 4.97321C15.6786 5.62124 16.1983 6.3933 16.552 7.24432C16.9057 8.09535 17.0863 9.00829 17.0834 9.92988C17.0834 13.7882 13.9225 16.9399 10.035 16.9399H10.0309C8.85394 16.9393 7.69572 16.6454 6.66086 16.0849C6.38193 15.9339 6.05603 15.8946 5.74919 15.9749L3.44753 16.5757L4.05086 14.3857Z"
    />
    <defs>
      <linearGradient :id="uniqueId" x1="23.25" y1="12.1519" x2="0.75" y2="12.1519" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
