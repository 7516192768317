<script setup>
import { Vue3Lottie } from "vue3-lottie";
import AnimationJSON from "/assets/lottie/like.json";

const props = defineProps({
  nft: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits(["nftLikeAction"]);

const { $api } = useNuxtApp();
const notificationStore = useNotificationStore();
const userStore = useUserStore();
const lottieAnimation = ref(null);
const isLiked = ref(false);

async function likeDislike() {
  if (!userStore.GET_ACCESS_TOKEN) {
    notificationStore.ADD({
      type: "error",
      title: "You are not authorized",
    });

    return;
  }

  isLiked.value = !isLiked.value;

  lottieAnimation.value.setDirection(isLiked.value ? "forward" : "reverse");
  lottieAnimation.value.play();

  const { data, error } = await $api.post(`nft/like/${props.nft._id}`);

  if (error.value || data.value?.error) {
    return;
  }

  emits("nftLikeAction", isLiked.value);
}

watch(
  () => props.nft,
  (newNft) => {
    isLiked.value = newNft?.likedByUser;

    if (lottieAnimation.value) {
      lottieAnimation.value.pause();
      lottieAnimation.value.setDirection(isLiked.value ? "forward" : "reverse");
      lottieAnimation.value.play();
    }
  },
  { deep: false, immediate: true }
);
</script>

<template>
  <ClientOnly>
    <AtomButton
      aria-label="Like"
      class="flex size-8 items-center justify-center !p-0 lg:size-9"
      type="button"
      @click.stop="likeDislike()"
    >
      <Vue3Lottie
        ref="lottieAnimation"
        :animation-data="AnimationJSON"
        class=""
        height="100%"
        width="100%"
        :loop="1"
        :speed="2"
        :auto-play="true"
        :direction="!isLiked ? 'reverse' : 'forward'"
      />
    </AtomButton>
  </ClientOnly>
</template>
