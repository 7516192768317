<script setup>
import Popper from "vue3-popper";
const config = useRuntimeConfig();
const notificationStore = useNotificationStore();

const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  image: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "",
  },
});

// ==================== CONSTANTS ====================

const isMailsVisible = ref(false);
const url = `${config.public.domain}${props.url}`;
</script>

<template>
  <ClientOnly>
    <Popper placement="top-end" style="display: flex; border: 0; margin: 0" @click.stop>
      <AtomButton class="flex items-center" aria-label="Share Dropdown" type="button">
        <IconShare :class="[props.color ? `fill-${props.color}` : '', 'size-5 lg:size-6']" />
      </AtomButton>
      <template #content>
        <div
          class="grid w-20 grid-cols-2 place-items-center gap-2 rounded-xl bg-white p-2 shadow-around shadow-black/25 dark:bg-slate-800 dark:shadow-[#00bbba] lg:w-24 lg:gap-3 lg:p-3"
        >
          <NuxtLink
            :to="`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(
              props.image
            )}&description=${encodeURIComponent(props.title)}`"
            target="_blank"
            rel="noopener"
            @click.stop
          >
            <IconPinterest class="size-5 lg:size-6" />
          </NuxtLink>
          <NuxtLink
            :to="`http://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(
              props.title
            )}&image=${encodeURIComponent(props.image)}`"
            target="_blank"
            rel="noopener"
            @click.stop
          >
            <IconReddit class="size-5 lg:size-6" />
          </NuxtLink>
          <div class="relative size-5 lg:size-6">
            <AtomButton aria-label="share-mail" @click.prevent="isMailsVisible = !isMailsVisible">
              <IconMail class="size-5 lg:size-6" />
            </AtomButton>
            <transition
              enter-active-class="transition ease-in duration-100"
              enter-from-class="scale-0"
              enter-to-class="scale-100"
              leave-active-class="transition ease-out duration-100"
              leave-from-class="scale-100"
              leave-to-class="scale-0"
            >
              <div
                v-if="isMailsVisible"
                class="absolute -left-14 top-1/2 z-50 flex -translate-y-1/2 flex-col gap-2 rounded-xl bg-white p-2 shadow-around shadow-black/25 dark:bg-slate-800 dark:shadow-[#00bbba] lg:-left-16"
              >
                <NuxtLink
                  :to="`https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${encodeURIComponent(
                    props.title
                  )}&body=Thought you might enjoy reading this: ${encodeURIComponent(url)}`"
                  target="_blank"
                  rel="noopener"
                  @click.stop
                >
                  <IconGmail class="size-5 lg:size-6" />
                </NuxtLink>
                <NuxtLink
                  :to="`https://outlook.live.com/mail/0/deeplink/compose?subject=${encodeURIComponent(
                    props.title
                  )}&body=Thought you might enjoy reading this: ${encodeURIComponent(url)}`"
                  target="_blank"
                  rel="noopener"
                  @click.stop
                >
                  <IconOutlook class="size-5 lg:size-6" />
                </NuxtLink>
                <NuxtLink
                  :to="`http://compose.mail.yahoo.com/?subj=${encodeURIComponent(
                    props.title
                  )}&body=Thought you might enjoy reading this: ${encodeURIComponent(url)}`"
                  target="_blank"
                  rel="noopener"
                  @click.stop
                >
                  <IconYahoo class="size-5 lg:size-6" />
                </NuxtLink>
                <NuxtLink
                  :to="`mailto:?subject=${encodeURIComponent(
                    props.title
                  )}&body=Thought you might enjoy reading this: ${encodeURIComponent(url)}`"
                  target="_blank"
                  rel="noopener"
                  @click.stop
                >
                  <IconMail class="size-5 lg:size-6" />
                </NuxtLink>
              </div>
            </transition>
          </div>
          <NuxtLink
            :to="`https://twitter.com/intent/tweet?text=${encodeURIComponent(props.title)}&url=${encodeURIComponent(
              url
            )}`"
            target="_blank"
            rel="noopener"
            @click.stop
          >
            <IconTwitter class="size-5 lg:size-6" />
          </NuxtLink>
          <NuxtLink
            :to="`https://api.whatsapp.com/send?text=${encodeURIComponent(`${props.title} - ${url}`)}`"
            target="_blank"
            rel="noopener"
            @click.stop
          >
            <IconWhatsapp class="size-5 lg:size-6" />
          </NuxtLink>
          <NuxtLink
            :to="`https://facebook.com/sharer.php?u=${url}&t=${props.title}`"
            target="_blank"
            rel="noopener"
            @click.stop
          >
            <IconFacebook class="size-5 lg:size-6" />
          </NuxtLink>
          <AtomButton aria-label="Copy" type="button" @click.prevent="copyText(notificationStore, url)">
            <IconCopy class="size-5 lg:size-6" />
          </AtomButton>
        </div>
      </template>
    </Popper>
  </ClientOnly>
</template>
