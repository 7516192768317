<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.001 0 0.319 0.307" width="24" height="23.011">
    <path
      d="M0.275 0.019H0.109A0.013 0.013 0 0 0 0.096 0.033V0.048l0.093 0.029L0.288 0.048V0.033A0.013 0.013 0 0 0 0.275 0.019z"
      fill="#0364b8"
    />
    <path
      d="M0.304 0.167A0.109 0.109 0 0 0 0.307 0.154a0.006 0.006 0 0 0 -0.003 -0.006l0 0 0 0L0.2 0.089a0.014 0.014 0 0 0 -0.001 -0.001 0.014 0.014 0 0 0 -0.013 0 0.014 0.014 0 0 0 -0.001 0.001L0.08 0.148l0 0 0 0A0.006 0.006 0 0 0 0.077 0.154a0.109 0.109 0 0 0 0.003 0.013l0.11 0.081z"
      fill="#0a2767"
    />
    <path d="M0.23 0.048h-0.067l-0.019 0.029L0.163 0.106l0.067 0.058h0.058v-0.058z" fill="#28a8ea" />
    <path d="M0.096 0.048h0.067v0.058h-0.067z" fill="#0078d4" />
    <path d="M0.23 0.048h0.058v0.058h-0.058z" fill="#50d9ff" />
    <path d="M0.23 0.163l-0.067 -0.058h-0.067v0.058l0.067 0.058 0.104 0.017z" fill="#0364b8" />
    <path d="M0.096 0.048H0.288" fill="none" />
    <path d="M0.163 0.106h0.067v0.058h-0.067z" fill="#0078d4" />
    <path d="M0.096 0.163h0.067v0.058h-0.067z" fill="#064a8c" />
    <path d="M0.23 0.163h0.058v0.058h-0.058z" fill="#0078d4" />
    <path
      d="M0.194 0.242l-0.113 -0.083 0.005 -0.008s0.103 0.059 0.105 0.06a0.005 0.005 0 0 0 0.004 0l0.105 -0.06 0.005 0.008z"
      fill="#0a2767"
      opacity=".5"
    />
    <path
      d="M0.304 0.159l0 0 0 0 -0.104 0.059a0.014 0.014 0 0 1 -0.014 0.001l0.036 0.049 0.079 0.017v0A0.014 0.014 0 0 0 0.307 0.274V0.154a0.006 0.006 0 0 1 -0.003 0.006z"
      fill="#1490df"
    />
    <path
      d="M0.307 0.274v-0.007l-0.096 -0.055 -0.012 0.007a0.014 0.014 0 0 1 -0.014 0.001l0.036 0.049 0.079 0.017v0A0.014 0.014 0 0 0 0.307 0.274z"
      opacity=".05"
    />
    <path
      d="M0.307 0.277L0.202 0.217l-0.002 0.001a0.014 0.014 0 0 1 -0.014 0.001l0.036 0.049 0.079 0.017v0a0.014 0.014 0 0 0 0.005 -0.008z"
      opacity=".1"
    />
    <path
      d="M0.08 0.159v0h0l0 0A0.006 0.006 0 0 1 0.077 0.154v0.12A0.014 0.014 0 0 0 0.091 0.288h0.202a0.014 0.014 0 0 0 0.004 0 0.006 0.006 0 0 0 0.002 -0.001 0.001 0.001 0 0 0 0.001 0 0.01 0.01 0 0 0 0.002 -0.001c0 0 0 0 0 0z"
      fill="#28a8ea"
    />
    <path
      d="M0.173 0.237V0.08A0.013 0.013 0 0 0 0.16 0.067H0.096V0.139l-0.016 0.009 0 0 0 0A0.006 0.006 0 0 0 0.077 0.154v0V0.154h0.083A0.013 0.013 0 0 0 0.173 0.237z"
      opacity=".1"
    />
    <path
      d="M0.163 0.246V0.09A0.013 0.013 0 0 0 0.15 0.077H0.096V0.139l-0.016 0.009 0 0 0 0A0.006 0.006 0 0 0 0.077 0.154v0V0.154h0.074A0.013 0.013 0 0 0 0.163 0.246z"
      opacity=".2"
    />
    <path
      d="M0.163 0.227V0.09A0.013 0.013 0 0 0 0.15 0.077H0.096V0.139l-0.016 0.009 0 0 0 0A0.006 0.006 0 0 0 0.077 0.154v0V0.154h0.074A0.013 0.013 0 0 0 0.163 0.227z"
      opacity=".2"
    />
    <path
      d="M0.154 0.227V0.09A0.013 0.013 0 0 0 0.141 0.077H0.096V0.139l-0.016 0.009 0 0 0 0A0.006 0.006 0 0 0 0.077 0.154v0V0.154h0.064A0.013 0.013 0 0 0 0.154 0.227z"
      opacity=".2"
    />
    <path
      d="M0.013 0.077h0.128A0.013 0.013 0 0 1 0.154 0.09v0.128A0.013 0.013 0 0 1 0.141 0.23H0.013A0.013 0.013 0 0 1 0 0.218V0.09A0.013 0.013 0 0 1 0.013 0.077z"
      fill="#0078d4"
    />
    <path
      d="M0.037 0.129a0.04 0.04 0 0 1 0.016 -0.017A0.048 0.048 0 0 1 0.078 0.106a0.044 0.044 0 0 1 0.023 0.006 0.04 0.04 0 0 1 0.015 0.017 0.054 0.054 0 0 1 0.005 0.024 0.057 0.057 0 0 1 -0.006 0.026 0.041 0.041 0 0 1 -0.016 0.017A0.046 0.046 0 0 1 0.076 0.202a0.045 0.045 0 0 1 -0.024 -0.006 0.04 0.04 0 0 1 -0.016 -0.017 0.052 0.052 0 0 1 -0.005 -0.024 0.058 0.058 0 0 1 0.005 -0.025zm0.017 0.041a0.026 0.026 0 0 0 0.009 0.011 0.023 0.023 0 0 0 0.014 0.004 0.024 0.024 0 0 0 0.015 -0.004 0.025 0.025 0 0 0 0.009 -0.011 0.044 0.044 0 0 0 0.003 -0.016 0.049 0.049 0 0 0 -0.003 -0.016 0.026 0.026 0 0 0 -0.008 -0.012 0.023 0.023 0 0 0 -0.015 -0.005 0.024 0.024 0 0 0 -0.014 0.004 0.026 0.026 0 0 0 -0.009 0.012 0.046 0.046 0 0 0 0 0.033z"
      fill="#fff"
    />
    <path d="M0 0h0.307v0.307H0z" fill="none" />
  </svg>
</template>
