<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 291.319 291.319"
    xml:space="preserve"
    width="24"
    height="24"
  >
    <g>
      <path
        style="fill: #720e9e"
        d="M12 0c6.628 0 12 5.373 12 12c0 6.628 -5.372 12 -12 12S0 18.628 0 12C0 5.373 5.372 0 12 0z"
      />
      <path
        style="fill: #ffffff"
        d="M17.494 9.473l0.013 -0.002l0.398 -0.442l-0.02 -0.001l0.042 -0.066h-5.569l0.215 0.762h1.519l-2.558 2.366c-0.522 -0.761 -1.745 -2.511 -2.599 -3.824h1.586v-0.54l0.022 -0.155l-0.022 -0.003v-0.063H4.501v0.761H6.384c0.731 0.604 3.902 4.453 4.017 4.82c0.046 0.344 0.113 2.376 -0.024 2.529c-0.164 0.236 -1.88 0.109 -2.182 0.131l-0.011 0.746c0.552 0.016 2.208 -0.001 2.767 -0.001c1.101 0 3.029 -0.029 3.301 -0.007l0.034 -0.707l-2.216 -0.033c-0.046 -0.32 -0.097 -2.342 -0.051 -2.562c0.209 -0.64 3.61 -3.287 3.959 -3.387l0.329 -0.074h1.027C17.334 9.721 17.494 9.473 17.494 9.473zM16.663 14.753l0.963 0.073l1.123 -4.117c-0.188 -0.007 -1.891 -0.159 -2.112 -0.2L16.663 14.753zM16.497 15.413l0.005 1.006l0.487 0.041l0.527 0.037l0.154 -0.984l-0.555 -0.026C17.116 15.487 16.497 15.413 16.497 15.413z"
      />
    </g>
  </svg>
</template>
